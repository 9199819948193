//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-72:_4724,_540,_5312,_7876,_2704,_3152,_280,_244;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-72')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-72', "_4724,_540,_5312,_7876,_2704,_3152,_280,_244");
        }
      }catch (ex) {
        console.error(ex);
      }